<template>
  <div>
    <div class="form-header font-weight-bolder pl-10 pr-15 py-5">
      <inline-svg src="/media/svg/icons/Navigation/Angle-left.svg" class="cursor-pointer" @click="goBackToLogin"/>
      {{ $t('label.password_recovery') }}
    </div>
    <div v-if="!emailSent" class="p-15 py-sm-10">
      <div class="font-weight-bolder">
        {{ $t('placeholder.enter_email') }}
      </div>
      <div>{{ $t('description.reset_password_in_two_steps') }}</div>

      <floating-input-form-group
        class="mt-8 mb-16"
        :value="form.email"
        :placeholder="$t('label.email')"
        input-type="email"
        :error="error.email"
        @input="form.email = $event"
      />

      <div class="text-center">
        <button class="btn btn-green font-weight-bold px-9 py-4 my-3 font-size-3 w-100 mb-5" @click="submitForm">
          {{ $t('btn.confirm') }}
        </button>
      </div>
    </div>
    <div v-else class="px-15 py-10">
      <div class="text-center">
        <inline-svg src="/media/svg/icons/General/Success.svg"/>
        <div class="h4 mt-2">{{ $t('description.password_recovery_instructions_sent') }}</div>
        <button class="btn btn-green font-weight-bold px-9 py-4 my-3 font-size-3 w-100 mt-15" @click="goBackToLogin">
          {{ $t('label.authorization') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {RESET_PASSWORD} from "@/store/core/auth.module";
import {formValidation} from '@/mixins/formValidation';
import FloatingInputFormGroup from '@/components/elements/form-groups/FloatingInputFormGroup.vue';

export default {
  name: "login",
  components: {
    FloatingInputFormGroup
  },
  mixins: [formValidation],
  data: () => ({
    emailSent: false,
    form: {
      email: ''
    },
    error: {
      email: ''
    }
  }),
  beforeMount() {
    this.$emit('title', this.$t('title.forgot_password'));
  },
  methods: {
    validateForm() {
      this.error.email = this.validateEmail(this.form.email);

      return !Object.values(this.error).some(v => (v !== null && v !== ''));
    },
    submitForm() {
      if (!this.validateForm()) return false;

      this.$store.dispatch(RESET_PASSWORD, this.form)
        .then(() => this.emailSent = true)
        .catch(() => this.error.email = 'error.something_goes_wrong');
    },
    goBackToLogin() {
      this.emailSent = false;
      this.$router.push({name: 'login'});
    }
  }
};
</script>

<style lang="scss" scoped>
.form-header {
  border-bottom: 1px solid #6E7173;
}
</style>

<style lang="scss" scoped>
@media (max-width: 767px) {
  .form-header {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
    font-size: 16px;
  }
}
</style>